import React, { useContext } from "react";
import { DialogProvider } from "./contexts/DialogContext";
import Login from "./pages/Signup/Login";
import "./index.css";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedRoute from "./routing/ProtectedRoute";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// core layout
import Layout from "./components/Layouts/Layout";

// tasks
import TrackingRequest from "./pages/TrackingRequest";

const queryClient = new QueryClient();

// TODO: Move route defs to a separate file
const router = createBrowserRouter([
  { path: "login", element: <Login /> },

  // home route
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <TrackingRequest />,
      },
    ],
  },
]);

function Content() {
  const { loadingAuth } = useContext(AuthContext);

  // If auth is loading, then show app loading state
  if (loadingAuth) return <div>Loading</div>;

  return (
    <div style={{ height: "100vh" }} className="bg-white font-vietnam">
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <QueryClientProvider client={queryClient}>
        <DialogProvider>
          <RouterProvider router={router} />
        </DialogProvider>
      </QueryClientProvider>
    </div>
  );
}

function App() {
  return (
    // Child components get context passed from parent in tree, so need to wrap
    <AuthProvider>
      <Content />
    </AuthProvider>
  );
}

export default App;
