import { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CreditCardIcon, LogOutIcon } from "lucide-react";
import { useAuth } from "../../contexts/AuthContext";
import { useDialog } from "../../contexts/DialogContext";
import Login from "../../pages/Signup/Login";

export default function Layout() {
  const navigate = useNavigate();
  const { isAdmin, session, supabase } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const { openDialog } = useDialog();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSignIn = () => {
    openDialog(<Login />);
  };

  const getSubscriptionStatusColor = (status) => {
    switch (status) {
      case "active":
        return "bg-green-500";
      case "past_due":
        return "bg-yellow-500";
      case "canceled":
      case "inactive":
        return "bg-red-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <main className="h-full flex flex-col">
      <header className="sticky top-0 z-10 bg-white">
        <div className="border-b border-gray-200">
          <div className="flex items-center justify-between px-4 py-3.5">
            <div className="flex-1 flex justify-start">
              <img
                style={{ width: 160 }}
                src="./trackFreightLogo.svg"
                alt="Track Freight Logo"
              />
            </div>
            <div className="flex-2 flex justify-center hidden sm:flex">
              <div className="flex gap-2 text-xs leading-7 text-center rounded-[46px]">
                <div className="text-neutral-700 w-full">
                  <a
                    href="https://calendly.com/rectanglehq/intro"
                    className="text-neutral-700 "
                  >
                    Get real-time tracking data integrated directly into your
                    own systems{" "}
                  </a>
                  <span className="font-semibold">
                    <a
                      className="text-blue-500 ml-2"
                      href="https://calendly.com/rectanglehq/intro"
                    >
                      Book a call
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="flex-1 flex justify-end">
              {session?.user?.email ? (
                <div className="relative flex items-center" ref={dropdownRef}>
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    style={{ backgroundColor: "#353E37" }}
                    className="flex items-center justify-center px-3 py-2 text-xs rounded-xl text-white font-semibold cursor-pointer"
                  >
                    <p>{session.user.email[0].toUpperCase()}</p>
                  </button>
                  {dropdownOpen && (
                    <div
                      className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                      style={{ top: "100%" }}
                    >
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <a
                          href="https://billing.stripe.com/p/login/14k7ti8zk5Mkd8YcMM"
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          <CreditCardIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Billing
                        </a>
                        <a
                          href="#"
                          onClick={() => supabase.auth.signOut()}
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                        >
                          <LogOutIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          Sign out
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <button
                  onClick={handleSignIn}
                  style={{ backgroundColor: "#353E37" }}
                  className="flex items-center justify-center px-3 py-2 text-xs rounded-full text-white font-semibold cursor-pointer"
                >
                  <p className="px-2">Sign in</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </header>
      <div className="flex flex-1 overflow-hidden">
        <main className="flex-1 overflow-y-auto">
          <Outlet />
        </main>
      </div>
    </main>
  );
}
