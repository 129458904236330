import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

const Maze = ({ startPosition = "topLeft" }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.clientWidth,
          height: containerRef.current.clientHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (!canvasRef.current || dimensions.width === 0 || dimensions.height === 0)
      return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const SIZE = 2;
    const SPACING = 3; // Increased from 2 to 3 to add 1px more spacing
    const SPEED = 1;
    let COLS = Math.ceil(dimensions.width / SPACING);
    let ROWS = Math.ceil(dimensions.height / SPACING);

    const OPEN = "#ffc952";
    const CLOSED = "#ff7473";
    const PATH = "#4bf442";
    const INITIAL_COLOR = "#F1F3F6"; // Dark grey

    if (COLS % 2 === 0) COLS--;
    if (ROWS % 2 === 0) ROWS--;

    function cycle() {
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, dimensions.width, dimensions.height);
      ctx.fillStyle = INITIAL_COLOR;

      const generator = function* (rows, cols) {
        let grid = Array(rows)
          .fill()
          .map(() => Array(cols).fill(1));
        let [r, c] = [Math.floor(rows / 2), Math.floor(cols / 2)];
        yield [r, c];
        grid[r][c] = 0;

        let stack = [[r, c, [0, 1, 2, 3]]];
        while (stack.length > 0) {
          let [r, c, directions] = stack.pop();
          while (directions.length > 0) {
            let direction = directions.splice(
              Math.floor(Math.random() * directions.length),
              1
            )[0];
            let [nr, nc] = [
              [r, c - 2],
              [r + 2, c],
              [r, c + 2],
              [r - 2, c],
            ][direction];
            if (
              nr >= 0 &&
              nr < rows &&
              nc >= 0 &&
              nc < cols &&
              grid[nr][nc] === 1
            ) {
              grid[(r + nr) / 2][(c + nc) / 2] = 0;
              grid[nr][nc] = 0;
              yield [nr, nc];
              stack.push([r, c, directions]);
              stack.push([nr, nc, [0, 1, 2, 3]]);
              break;
            }
          }
        }
        yield grid;
      };

      const iterator = generator(ROWS, COLS);

      function maze(iterator, next) {
        const startTime = Date.now();
        return function () {
          let i = SPEED;
          do {
            let [x, y] = next.value;
            if (typeof x !== "number") {
              // Maze generation complete, start pathfinding
              setTimeout(cycle, 4000);
              return;
            }
            const elapsedTime = Date.now() - startTime;
            const progress = Math.min(elapsedTime / 5000, 1); // 5 seconds fade
            const r = Math.round(51 + (211 - 51) * progress);
            const g = Math.round(56 + (216 - 56) * progress);
            const b = Math.round(61 + (221 - 61) * progress);
            ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
            ctx.fillRect(y * SPACING, x * SPACING, SIZE, SIZE);
            next = iterator.next();
          } while (!next.done && --i > 0);

          if (!next.done) {
            requestAnimationFrame(maze(iterator, next));
          }
        };
      }

      requestAnimationFrame(maze(iterator, iterator.next()));
    }

    cycle();
  }, [dimensions, startPosition]);

  return (
    <motion.div
      ref={containerRef}
      style={{ width: "100%", height: "100%", opacity: 0.34 }}
    >
      <canvas
        ref={canvasRef}
        width={dimensions.width}
        height={dimensions.height}
      />
    </motion.div>
  );
};

export default Maze;
