import React, { useEffect, useState, useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import { POST, GET } from "../../api/requests";
import { useAuth } from "../../contexts/AuthContext";
import { useDialog } from "../../contexts/DialogContext";
import Checkout from "../../components/Checkout";
import { motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import posthog from "posthog-js";

import Login from "../Signup/Login";
import {
  Calendar,
  RefreshCw,
  ChevronDown,
  Mail,
  Globe,
  AlertCircle,
  Box,
  Link,
  X,
} from "lucide-react";
import Maze from "./Maze";
import { ColorRing } from "react-loader-spinner";

posthog.init("phc_CRSYIASMfc9l9rWJ1BWO3ICBRLkhQ6yYQN26OnMgDnx", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always",
});
export default function TrackingRequest() {
  const [trackingUrl, setTrackingUrl] = useState("");
  const [loadReference, setLoadReference] = useState("");
  const [error, setError] = useState(null);
  const { isAdmin, session } = useAuth();
  const { openDialog, closeDialog } = useDialog();

  const [deadline, setDeadline] = useState(
    () => localStorage.getItem("deadline") || "24 hours"
  );
  const [interval, setInterval] = useState(
    () => localStorage.getItem("interval") || "15 mins"
  );
  const [notificationType, setNotificationType] = useState("email");
  const [email, setEmail] = useState(() => localStorage.getItem("email") || "");
  const [webhook, setWebhook] = useState(
    () => localStorage.getItem("webhook") || ""
  );

  // Check if trackingUrl contains any of the supported domains
  const supportedDomains = [
    { name: "ivia", regex: /https.*ivia\.com/i },
    { name: "samsara", regex: /https.*samsara\.com/i },
    { name: "xeld", regex: /https.*xeld\.com/i },
    { name: "zeekgps", regex: /https.*zeekgps\.com/i },
    { name: "motive", regex: /https.*motive\.com/i },
    { name: "odfl", regex: /https.*odfl\.com/i },
    { name: "orbcomm", regex: /https.*orbcomm\.com/i },
    { name: "tteld", regex: /https.*tteld\.com/i },
    { name: "zonar", regex: /https.*zonar\.com/i },
    { name: "trimble", regex: /https.*trimble\.com/i },
    { name: "webfleet", regex: /https.*webfleet\.com/i },
    { name: "randmcnally", regex: /https.*randmcnally\.com/i },
    { name: "omnitracs", regex: /https.*omnitracs\.com/i },
    { name: "optimaeld", regex: /https.*optimaeld\.com/i },
    { name: "oneview", regex: /https.*oneview\.com/i },
    { name: "lytx", regex: /https.*lytx\.com/i },
    { name: "onestep", regex: /https.*onestep\.com/i },
    { name: "lioneight", regex: /https.*lioneight\.com/i },
    { name: "gpstab", regex: /https.*gpstab\.com/i },
    { name: "fleetcomplete", regex: /https.*fleetcomplete\.com/i },
    { name: "eroad", regex: /https.*eroad\.com/i },
    { name: "att", regex: /https.*att\.com/i },
    { name: "apollo", regex: /https.*apollo\.com/i },
    { name: "assured", regex: /https.*assured\.com/i },
    {
      name: "manageourfreight",
      regex: /^https?:\/\/(?:www\.)?manageourfreight\.com\/.+/i,
    },
    { name: "milestone", regex: /^https?:\/\/(?:www\.)?milestone\.com\/.+/i },
    { name: "monarch", regex: /^https?:\/\/(?:www\.)?monarch\.com\/.+/i },
  ];

  const [validDomain, setValidDomain] = useState(null);
  const [validNotification, setValidNotification] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);

  const onSubscriptionComplete = () => {
    closeDialog();
    toast("Payment details added successfully.");
  };

  const trackingMutation = useMutation((trackingData) =>
    POST("/track", trackingData)
  );

  const { data: profile } = useQuery("profile", () => GET("/profile"), {
    enabled: !!session,
  });

  useEffect(() => {
    localStorage.setItem("deadline", deadline);
  }, [deadline]);

  useEffect(() => {
    localStorage.setItem("interval", interval);
  }, [interval]);

  useEffect(() => {
    localStorage.setItem("email", email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem("webhook", webhook);
  }, [webhook]);

  useEffect(() => {
    if (session) {
      setFormIsValid(validDomain && validNotification);
    } else {
      setFormIsValid(validNotification);
    }
  }, [validDomain, validNotification, session]);

  useEffect(() => {
    validateNotification();
  }, [notificationType, email, webhook]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if user is signed in

    if (!session) {
      // If user is not signed in, open dialog with Login component
      openDialog(<Login />);
      return; // Prevent form submission
    }

    trackingMutation.mutate(
      {
        trackingUrl,
        loadReference,
        deadline,
        interval,
        notificationType,
        ...(notificationType === "email" ? { email } : { webhook }),
      },
      {
        onError: (error) => {
          if (error.response && error.response.status === 402) {
            openDialog(
              <Checkout onSubscriptionComplete={onSubscriptionComplete} />
            );
          } else {
            toast("Unable to start tracking", {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            setError(error.response.data.error);
          }
        },
        onSuccess: () => {
          toast("Tracking started successfully", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setError(null);
          // Clear form or show success message
          setTrackingUrl("");
          setLoadReference("");
        },
      }
    );
  };

  const validateWebhook = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateTrackingUrl = (url) => {
    if (!url) {
      setValidDomain(null);
      return;
    }

    const matchedDomain = supportedDomains.find((domain) =>
      domain.regex.test(url)
    );
    if (matchedDomain) {
      setValidDomain(matchedDomain.name);
      setError("");
    } else {
      setValidDomain(false);
    }
  };

  const validateNotification = () => {
    if (notificationType === "email") {
      const isValid = validateEmail(email);
      setValidNotification(isValid);
    } else {
      const isValid = validateWebhook(webhook);
      setValidNotification(isValid);
    }
  };

  const logFormFill = useCallback(() => {
    posthog.capture("formFill", {
      trackingUrl,
      loadReference,
      deadline,
      interval,
      notificationType,
      email,
      webhook,
    });
  }, [
    trackingUrl,
    loadReference,
    deadline,
    interval,
    notificationType,
    email,
    webhook,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      logFormFill();
    }, 5000);

    return () => clearTimeout(timer);
  }, [
    trackingUrl,
    loadReference,
    deadline,
    interval,
    notificationType,
    email,
    webhook,
    logFormFill,
  ]);

  return (
    <div className="grid grid-cols-[20px_1fr_20px]  md:grid-cols-[100px_1fr_100px] lg:grid-cols-[200px_1fr_200px] h-full w-full overflow-x-hidden">
      <div className="col-span-3 grid grid-cols-[20px_1fr_20px] md:grid-cols-[100px_1fr_100px] lg:grid-cols-[200px_1fr_200px]">
        <div className="border-r"></div>
        <div className="w-full flex items-end pt-8 lg:pt-14 justify-center">
          <div
            style={{
              zIndex: 4,
              marginBottom: -15,
              background:
                "linear-gradient(98deg, rgba(255, 255, 255, 0.09) 44.01%, rgba(0, 0, 0, 0.09) 91.4%), #697179",
            }}
            className="inline-block px-5 text-xs font-medium py-1 leading-7 text-center text-white bg-gray-500 rounded-[46px]"
          >
            Instant FTL freight visibility
          </div>
        </div>
        <div className="border-l"></div>
      </div>
      <div className="border-r border-t border-b relative bg-slate-50">
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-white via-transparent to-transparent pointer-events-none"></div>
        <Maze startPosition="topLeft" />
      </div>
      <div className="p-4 border-t  border-b flex justify-center h-full">
        {/* Main content */}
        <div className="mx-auto pb-8 w-full max-w-[320px] md:max-w-[720px]">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="flex flex-col px-4 sm:px-6 items-center justify-center text-center"
          >
            <h1 className="font-bold lg:font-semibold text-3xl lg:text-4xl mt-8 md:mt-16 font-vietnam">
              Track freight from any ELD provider
            </h1>
            <p className="text-center text-base sm:text-lg lg:text-2xl mt-4 font-vietnam w-full mb-8 md:mb-14 text-slate-600">
              Enter a tracking link and we'll automatically send location
              updates to your inbox or endpoint every 15 minutes
            </p>
          </motion.div>

          <form onSubmit={handleSubmit} className="px-4 sm:px-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <label
                htmlFor="linkAndRef"
                className="block md:hidden text-sm font-medium text-gray-700 mb-1"
              >
                Tracking link
              </label>
              <div className="block md:flex items-center mt-1 w-full border border-gray-300 rounded-md shadow-sm">
                <div className="flex items-center md:w-3/4">
                  <div className="px-3 py-2">
                    {validDomain ? (
                      <img
                        src={`./logos/${validDomain}.png`}
                        alt={validDomain}
                        className="h-6 rounded-md w-6"
                      />
                    ) : validDomain === false ? (
                      <X
                        size={18}
                        strokeWidth={2.25}
                        className="text-red-500"
                      />
                    ) : (
                      <Link
                        size={18}
                        strokeWidth={2.25}
                        className="text-[#4C4E52]"
                      />
                    )}
                  </div>
                  <input
                    type="text"
                    id="trackingUrl"
                    placeholder="https://samsara.com.."
                    value={trackingUrl}
                    onChange={(e) => {
                      setTrackingUrl(e.target.value);
                      validateTrackingUrl(e.target.value);
                    }}
                    className="flex-1 bg-transparent pr-4 text-sm pl-0 py-2 focus:outline-none outline-none border-0 focus:border-0"
                  />
                </div>
                <div className=" border-t md:border-l md:border-t-0 flex items-center pl-2 border-l">
                  <Box
                    className="text-[#4C4E52] mr-2 ml-1"
                    strokeWidth={2.25}
                    size={18}
                  />
                  <input
                    type="text"
                    id="loadReference"
                    name="loadReference"
                    placeholder="Load reference"
                    value={loadReference}
                    onChange={(e) => setLoadReference(e.target.value)}
                    className="w-full bg-transparent px-2 py-2 text-sm focus:outline-none outline-none border-0 focus:border-0"
                  />
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              className="flex flex-col sm:flex-row flex-wrap gap-4 mt-10"
            >
              <div className="w-full sm:w-auto">
                <label
                  htmlFor="deadlineInterval"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Set duration and interval
                </label>
                <div className="flex border border-gray-300 rounded-md shadow-sm">
                  <div className="flex items-center pl-3">
                    <Calendar size={18} className="text-[#4C4E52]" />
                  </div>

                  <select
                    id="deadline"
                    name="deadline"
                    value={deadline}
                    onChange={(e) => {
                      setDeadline(e.target.value);
                      if (e.target.value === "24 hours") {
                        setInterval("30 mins");
                      }
                    }}
                    className="py-2 text-sm w-fit outline-none border-0"
                  >
                    <option value="24 hours">24 hours</option>
                    <option value="3 days">3 days</option>
                    <option value="1 week">1 week</option>
                  </select>

                  <div className="flex items-center pl-3 border-l">
                    <RefreshCw size={18} className="text-[#4C4E52]" />
                  </div>

                  <select
                    id="interval"
                    name="interval"
                    value={interval}
                    onChange={(e) => {
                      setInterval(e.target.value);
                    }}
                    className="py-2 w-fit text-sm bg-transparent outline-none border-0"
                  >
                    <option value="15 mins">15 mins</option>
                    <option value="30 mins">30 mins</option>
                    <option value="1 hour">Hourly</option>
                    {deadline !== "24 hours" && (
                      <option value="daily">Daily</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="flex-grow">
                <label
                  htmlFor="notificationTypeInput"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Destination
                </label>
                <div className="block md:flex overflow-hidden text-sm whitespace-nowrap rounded-lg border border-gray-300 border-solid bg-white bg-opacity-90 w-full shadow-[0px_1px_2px_rgba(0,0,0,0.04)] text-gray-800">
                  <div className="flex items-center">
                    <div className="flex items-center pl-3">
                      {notificationType === "email" ? (
                        validNotification === false ? (
                          <X size={16} className="h-5 w-5 text-red-500" />
                        ) : (
                          <Mail size={16} className="h-5 w-5 text-[#4C4E52]" />
                        )
                      ) : validNotification === false ? (
                        <X size={18} className="h-5 w-5 text-red-500" />
                      ) : (
                        <Globe className="text-[#4C4E52]" size={16} />
                      )}
                    </div>
                    <select
                      id="notificationType"
                      name="notificationType"
                      value={notificationType}
                      onChange={(e) => {
                        setNotificationType(e.target.value);
                        setEmail("");
                        setWebhook("");
                        validateNotification();
                      }}
                      className="py-2 w-fit text-sm border-0 outline-none"
                    >
                      <option value="email">Email</option>
                      <option value="webhook">Webhook</option>
                    </select>
                  </div>

                  {notificationType === "email" ? (
                    <input
                      type="email"
                      placeholder="example@email.com"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        validateNotification();
                      }}
                      className="flex-grow w-full border-slate-300 border-t md:border-l md:border-t-0 text-sm py-2 bg-transparent focus:outline-none outline-none border-0 focus:border-0"
                    />
                  ) : (
                    <input
                      type="url"
                      placeholder="Webhook URL"
                      value={webhook}
                      onChange={(e) => {
                        setWebhook(e.target.value);
                        validateNotification();
                      }}
                      className="flex-grow w-full border-slate-300 border-t md:border-l md:border-t-0 text-sm py-2 bg-transparent focus:outline-none outline-none border-0 focus:border-0"
                    />
                  )}
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.8 }}
              className="md:flex items-center justify-between mt-4"
            >
              <p className="text-xs" style={{ color: "#727980" }}>
                {!session ? (
                  "10 Shipments tracked for free. No credit card required. Then starting at $1 per link"
                ) : profile?.usage < 5 ? (
                  <p>
                    {5 - profile?.usage} free tracking links remaining. Then
                    starting at $1 per link
                  </p>
                ) : (
                  <span className="text-slate-800 font-medium">
                    Price: $
                    {interval === "24 hours" || interval === "3 days"
                      ? "1"
                      : "2"}
                    . Only charged for successful pings
                  </span>
                )}
              </p>

              <motion.button
                disabled={trackingMutation.isLoading || !formIsValid}
                type="submit"
                className={`bg-brand-green mt-4 md:mt-0 px-4 justify-center flex py-2.5 text-brand-text rounded-lg font-medium text-sm w-full md:w-fit ${
                  trackingMutation.isLoading
                    ? "cursor-wait"
                    : !formIsValid
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <div className="flex items-center space-x-2">
                  {trackingMutation.isLoading ? (
                    <ColorRing
                      visible={true}
                      height="20"
                      width="20"
                      ariaLabel="blocks-loading"
                      colors={["#000", "#000", "#000", "#000", "#000"]}
                    />
                  ) : null}
                  <p className="text-brand-text">Start tracking</p>
                </div>
              </motion.button>
            </motion.div>
          </form>
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 2 }}
            className="border hidden md:flex text-xs pl-5 space-x-2 pr-5 py-3 flex items-center mx-auto mt-20 rounded-full w-fit"
          >
            <img style={{ width: 16 }} src="./rectangleIcon.png" />
            <p>
              Automate your freight operations with Rectangle{" "}
              <a
                href="https://rectanglehq.com"
                className="text-blue-500 font-medium ml-2 text-xs "
              >
                Learn more
              </a>
            </p>{" "}
          </motion.div>

          <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
              },

              // Default options for specific types
              success: {
                duration: 3000,
                theme: {
                  primary: "green",
                  secondary: "black",
                },
              },
            }}
          />
        </div>
      </div>
      <div className="border-l border-t border-b relative bg-slate-50">
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent via-transparent to-white pointer-events-none"></div>
        <Maze startPosition="bottomRight" />
      </div>
      <div className="col-span-3 border-b grid grid-cols-[20px_1fr_20px] md:grid-cols-[100px_1fr_100px] lg:grid-cols-[200px_1fr_200px]">
        <div className="border-r"></div>
        <div className="flex flex-col sm:flex-row items-center text-xs justify-between px-8 py-4">
          <p className="mb-2 sm:mb-0">Rectangle Labs, Inc. Built in Chicago.</p>
          <div className="flex flex-col sm:flex-row items-center text-slate-400 space-y-2 sm:space-y-0 sm:space-x-2">
            <a href="https://rectanglehq.com/terms">Terms</a>
            <a href="https://rectanglehq.com/privacy">Privacy</a>
            <a href="https://rectanglehq.com/contact">Contact</a>
            <a className="text-slate-600" href="mailto:support@rectanglehq.com">
              support@rectanglehq.com
            </a>
          </div>
        </div>
        <div className="border-l"></div>
      </div>
    </div>
  );
}
