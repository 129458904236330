import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { motion } from "framer-motion";
import { CreditCard } from "lucide-react";
import { useMutation } from "react-query";

import { ColorRing } from "react-loader-spinner";
import { GET, POST } from "../api/requests";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_STRIPE_KEY);
const CheckoutForm = ({ email, onSubscriptionComplete }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const createSubscriptionMutation = useMutation(
    (paymentMethodId) =>
      POST("/stripe/create-subscription", { paymentMethodId }),
    {
      onSuccess: async ({ subscriptionId, clientSecret }) => {
        console.log({ subscriptionId, clientSecret });

        if (clientSecret) {
          const { error: confirmationError } = await stripe.confirmCardPayment(
            clientSecret
          );
          if (confirmationError) {
            setError(confirmationError.message);
          } else {
            onSubscriptionComplete(subscriptionId);
          }
        } else {
          onSubscriptionComplete(subscriptionId);
        }
        setProcessing(false);
      },
      onError: (error) => {
        setError(error.message);
        setProcessing(false);
      },
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      setError(result.error.message);
      setProcessing(false);
    } else {
      createSubscriptionMutation.mutate(result.paymentMethod.id);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex items-center mt-1 w-full border border-gray-300 rounded-md shadow-sm">
        <div className="px-3 py-2">
          <CreditCard size={18} strokeWidth={2.25} className="text-[#4C4E52]" />
        </div>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
          className="flex-1 pr-4 text-sm pl-0 py-3 focus:outline-none outline-none border-0 focus:border-0"
        />
      </div>
      <motion.button
        type="submit"
        className={`bg-brand-green px-4 justify-center flex py-2.5 text-brand-text rounded-lg font-medium text-sm w-full ${
          !stripe || processing ? "cursor-wait" : "cursor-pointer"
        }`}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        disabled={!stripe || processing}
      >
        <div className="flex items-center space-x-2">
          {processing ? (
            <ColorRing
              visible={true}
              height="20"
              width="20"
              ariaLabel="blocks-loading"
              colors={["#000", "#000", "#000", "#000", "#000"]}
            />
          ) : null}
          <p className="text-brand-text">Subscribe</p>
        </div>
      </motion.button>
      {error && <div className="text-red-500 text-sm">{error}</div>}
    </form>
  );
};

const Checkout = ({ onSubscriptionComplete }) => {
  const [email, setEmail] = useState("");
  const [usage, setUsage] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Fetch user data and usage on component mount
    // This is a placeholder and should be implemented based on your authentication system
  }, []);

  return (
    <div className="h-full max-w-[380px]">
      <div className="w-full gap-8 relative">
        <div className="w-full absolute h-24 top-0 left-0">
          <img src="./modalHeaderBg.png" alt="Modal Header Background" />
        </div>
        <div
          style={{ maxWidth: 500, zIndex: 10 }}
          className="mx-auto p-6 relative"
        >
          <motion.section
            className="checkout"
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.45, delay: 0.2 }}
          >
            <div className="space-y-2">
              <div className="flex overflow-hidden flex-col justify-center items-center px-4 bg-white rounded-2xl border border-solid border-neutral-200 h-[73px] shadow-[0px_6px_19px_rgba(172,174,187,0.25)] w-[73px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d942fb51afa013048e034c04fbfb46046cf44290f324085c817cffbc3c6d7ec?placeholderIfAbsent=true&apiKey=a415a1a83be74f34bc616fca11f07249"
                  className="object-contain w-full aspect-[1.69]"
                  alt="Rectangle Logo"
                />
              </div>
              <h1 className="font-semibold text-2xl font-space pt-4">
                Subscribe to Rectangle
              </h1>
              <div className="text-sm leading-2 pb-4 text-neutral-800 text-opacity-80">
                Enter your payment details to continue tracking. Tracking will
                be charged per link, starting at $1. Price will be shown before
                submitting.
              </div>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  email={email}
                  onSubscriptionComplete={onSubscriptionComplete}
                />
              </Elements>
            </div>
          </motion.section>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
