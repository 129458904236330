// Default imports
import { createContext, useContext, useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import axiosInstance from "../api/axiosInstance";

const supabase = createClient(
  "https://dizfqywncoihpuevvmpp.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRpemZxeXduY29paHB1ZXZ2bXBwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTY1NzAyODUsImV4cCI6MjAxMjE0NjI4NX0.aj9L5xvcStoULEFPFBVi7QwbUVCHH2I1LTp_aExBHfs"
);

// create context and client
export const AuthContext = createContext({
  session: null,
  setSession: () => {},
});

// export useContext hook
export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [token, setToken] = useState(null);
  const [loadingSessionComplete, setloadingSessionComplete] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setloadingSessionComplete(true);
      setIsAdmin(session?.user?.email?.includes("@rectanglehq.com"));
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setIsAdmin(session?.user?.email?.includes("@rectanglehq.com"));
      if (
        session?.access_token &&
        session.access_token !== undefined &&
        session.access_token !== token
      ) {
        setToken(session.access_token);
        axiosInstance.supabase = supabase;
        axiosInstance.defaults.headers.common["Authorization"] =
          session.access_token;
      }
    });

    return () => subscription.unsubscribe();
  }, [token]);

  const value = {
    session,
    setSession,
    isAdmin,
    token,
    supabase,
    loadingSessionComplete,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
