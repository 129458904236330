import { BASE_URL } from "./config";
import axiosInstance from "./axiosInstance";
import { jwtDecode } from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL || BASE_URL;
console.log({API_URL});
const request = async (method, path, data = null) => {
  const authToken = axiosInstance.defaults.headers.common["Authorization"];
  if (!authToken) {
    console.error("No token found in request");
    throw new Error("No token found in request");
  }

  // Check JWT expiry before requests
  let decodedToken = jwtDecode(authToken);
  let currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    // Get a new token from supabae
    const { data } = await axiosInstance.supabase.auth.refreshSession();
    const { session } = data;
    axiosInstance.defaults.headers.common["Authorization"] =
      session.access_token;
  }
  try {
    const response = await axiosInstance({
      method,
      url: API_URL + path,
      data,
    });
    return response.data;
  } catch (error) {
    console.error(`Error ${method} data: `, error);
    throw error;
  }
};

export const GET = (path) => request("get", path);
export const POST = (path, object) => request("post", path, object);
export const PUT = (path, object) => request("put", path, object);
export const DELETE = (path) => request("delete", path);
