import React, {
  Fragment,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { motion } from "framer-motion";

const DialogContext = createContext();

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [width, setWidth] = useState("max-w-md"); // useful if you want a dialog to be wider or narrower

  // when opening a dialog, we set the inner content from the component that calls the dialog
  const openDialog = useCallback((dialogContent, dialogWidth = "max-w-md") => {
    setContent(dialogContent);
    setWidth(dialogWidth);
    setIsOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  const value = { openDialog, closeDialog };

  return (
    <DialogContext.Provider value={value}>
      {children}
      <Transition show={isOpen} as={Fragment}>
        <Dialog onClose={closeDialog} className="relative z-50">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <motion.div>
                  <DialogPanel
                    className={`w-full ${width} transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all`}
                  >
                    {content}
                  </DialogPanel>
                </motion.div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </DialogContext.Provider>
  );
};
