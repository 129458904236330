import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";

// Import UI stuff for Supabase login UI
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useDialog } from "../../contexts/DialogContext";
import { motion } from "framer-motion";

const Login = ({}) => {
  const { supabase, setSession } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [step, setStep] = useState("login");
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const { closeDialog } = useDialog();
  // Whatever the route the user was trying to get to before signin in,
  // try redirecting them there once they are authenticated
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectParam = urlParams.get("redirect");
    setRedirect(redirectParam);
  }, []);

  async function signInWithEmail() {
    setIsLoading(true);
    const { data, error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: true,
        emailRedirectTo: "https://localhost:3000/welcome",
      },
    });

    console.log({ data });
    if (error) {
      setError(error.message);
      alert(JSON.stringify(error.message));
    } else {
      setStep("confirmEmail");
    }
    setIsLoading(false);
  }

  const verify = async (token) => {
    setIsVerifying(true);
    setOtpError("");
    const {
      data: { session },
      error,
    } = await supabase.auth.verifyOtp({
      email,
      token,
      type: "email",
    });

    if (session) {
      closeDialog();
    }

    if (error) {
      console.log({ error });
      setError(error);
      setOtpError("Invalid pin. Please try again.");
    }
    setIsVerifying(false);
  };

  if (supabase) {
    return (
      <div className="h-full min-w-[380px]">
        <div className="w-full gap-8 relative">
          <div className="w-full absolute h-24 top-0 left-0">
            <img src="./modalHeaderBg.png" />
          </div>
          <div
            style={{ maxWidth: 500, zIndex: 10 }}
            className="mx-auto p-6 relative"
          >
            {step == "login" && (
              <motion.section
                className="login"
                initial={{ opacity: 0, y: 200 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.45, delay: 0.2 }}
              >
                <div className="space-y-2">
                  <div className="flex overflow-hidden flex-col justify-center items-center px-4 bg-white rounded-2xl border border-solid border-neutral-200 h-[73px] shadow-[0px_6px_19px_rgba(172,174,187,0.25)] w-[73px]">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d942fb51afa013048e034c04fbfb46046cf44290f324085c817cffbc3c6d7ec?placeholderIfAbsent=true&apiKey=a415a1a83be74f34bc616fca11f07249"
                      className="object-contain w-full aspect-[1.69]"
                    />
                  </div>
                  <h1 className="font-semibold text-2xl font-space pt-4">
                    Sign in to Rectangle
                  </h1>
                  <div className="text-sm leading-2 pb-4 text-neutral-800 text-opacity-80">
                    We'll send a one-time code to your <br />
                    email address to sign in.
                  </div>
                  <p className="text-xs font-semibold">Email address</p>
                  <input
                    type="email"
                    disabled={isLoading}
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="flex items-center mt-1 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-4 focus:border-gray-300 focus:ring-slate-100 "
                  />
                  <div className="flex items-center pt-1 text-xs justify-between">
                    <p className="text-slate-500">
                      By signing in you agree to our <br />{" "}
                      <a
                        className="text-slate-600 font-medium"
                        href="https://rectanglehq.com/terms"
                      >
                        terms
                      </a>{" "}
                      and{" "}
                      <a
                        className="text-slate-600 font-medium"
                        href="https://rectanglehq.com/privacy"
                      >
                        privacy policy
                      </a>
                    </p>
                    <motion.button
                      disabled={isLoading}
                      onClick={() => signInWithEmail()}
                      className={`bg-brand-green px-4 justify-center flex py-3 text-brand-text rounded-lg font-medium text-sm w-fit ${
                        isLoading ? "cursor-wait" : "cursor-pointer"
                      }`}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <div className="flex items-center space-x-2">
                        {isLoading ? (
                          <ColorRing
                            visible={true}
                            height="20"
                            width="20"
                            ariaLabel="blocks-loading"
                            colors={["#000", "#000", "#000", "#000", "#000"]}
                          />
                        ) : null}
                        <p className="text-brand-text font-semibold">
                          Sign in{" "}
                        </p>
                      </div>
                    </motion.button>
                  </div>
                </div>
              </motion.section>
            )}

            {step == "confirmEmail" && (
              <motion.section
                className="login max-w-[315px]"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.75, delay: 0.3 }}
              >
                <div className="flex overflow-hidden flex-col justify-center items-center px-4 bg-white rounded-2xl border border-solid border-neutral-200 h-[73px] shadow-[0px_6px_19px_rgba(172,174,187,0.25)] w-[73px]">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d942fb51afa013048e034c04fbfb46046cf44290f324085c817cffbc3c6d7ec?placeholderIfAbsent=true&apiKey=a415a1a83be74f34bc616fca11f07249"
                    className="object-contain w-full aspect-[1.69]"
                  />
                </div>
                <h1 className="font-semibold text-2xl font-space pt-4">
                  Enter OTP
                </h1>
                <div className="text-sm leading-2 pb-4 text-neutral-800 text-opacity-80">
                  Enter the code we sent to <br /> {email}
                </div>
                <motion.div
                  className="flex mt-1 border w-fit divide-x overflow-hidden text-sm whitespace-nowrap rounded-lg bg-white bg-opacity-90 w-full shadow-[0px_1px_2px_rgba(0,0,0,0.04)] text-neutral-800 border-slate-300"
                  animate={otpError ? { x: [-5, 5, -5, 5, 0] } : {}}
                  transition={{ duration: 0.4, ease: "easeInOut" }}
                >
                  {[...Array(6)].map((_, index) => (
                    <div key={index} className="border-0">
                      <input
                        type="text"
                        maxLength="1"
                        value={otp[index] || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length <= 1) {
                            const newOtp = otp.split("");
                            newOtp[index] = value;
                            setOtp(newOtp.join(""));
                            if (value && index < 5) {
                              const nextInput =
                                e.target.parentNode.nextSibling?.querySelector(
                                  "input"
                                );
                              if (nextInput) nextInput.focus();
                            }
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          const pastedData = e.clipboardData
                            .getData("text")
                            .slice(0, 6);
                          const newOtp = pastedData.padEnd(6, "").split("");
                          setOtp(newOtp.join(""));
                          if (pastedData.length === 6) {
                            e.target.blur();
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") {
                            if (!e.target.value && index > 0) {
                              const prevInput =
                                e.target.parentNode.previousSibling?.querySelector(
                                  "input"
                                );
                              if (prevInput) {
                                prevInput.focus();
                                setOtp((prev) => {
                                  const newOtp = prev.split("");
                                  newOtp[index - 1] = "";
                                  return newOtp.join("");
                                });
                              }
                            } else {
                              setOtp((prev) => {
                                const newOtp = prev.split("");
                                newOtp[index] = "";
                                return newOtp.join("");
                              });
                            }
                          }
                        }}
                        className="w-12 h-12 border-0 w-full text-center text-slate-800 text-xl bg-transparent flex items-center border-transparent focus:outline-none focus:ring-0 focus:border-transparent "
                      />
                      {index < 5 && <div className="w-4" />}
                    </div>
                  ))}
                </motion.div>
                {error && <p>{error.msg}</p>}
                <div className="flex items-center mt-4 justify-between">
                  <motion.p
                    onClick={() => {
                      setOtp("");
                      setStep("login");
                    }}
                    className="flex cursor-pointer items-center space-x-2"
                    whileHover={{ x: -5 }}
                  >
                    <ArrowLeftIcon className="w-4" />
                    <p className="font-semibold text-xs text-gray-600">
                      Go back
                    </p>
                  </motion.p>

                  <motion.button
                    disabled={isVerifying}
                    onClick={() => verify(otp)}
                    className={`bg-brand-green px-4 justify-center flex py-3 text-brand-text rounded-lg font-medium text-sm w-fit ${
                      isVerifying ? "cursor-wait" : "cursor-pointer"
                    }`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <div className="flex items-center space-x-2">
                      {isVerifying ? (
                        <ColorRing
                          visible={true}
                          height="20"
                          width="20"
                          ariaLabel="blocks-loading"
                          colors={["#000", "#000", "#000", "#000", "#000"]}
                        />
                      ) : null}
                      <p className="text-brand-text">Verify</p>
                    </div>
                  </motion.button>
                </div>
              </motion.section>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Login;
