import * as React from "react";
import * as ReactDOM from "react-dom/client";

import "./index.css";

import App from "./App";

import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: "https://app.posthog.com",
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);
